import styled from 'styled-components';

export const InfoContainer = styled.div`
   color : #fff;
   background-color: ${({ lightBg }) => (lightBg ? '#fff' : 'var(--color-dark)')};

   @media screen and (max-width: 780px) {
     padding: 10px;
   }
`;

export const InfoWrapper = styled.div`
   /* display: grid; */
   z-index: 1;
   min-height: 100vh;
   width: 100%;
   max-width: 1100px;
   margin-right: auto;
   margin-left: auto;
   padding: 100px 24px;
   justify-content: center;
`;

export const InfoRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({ imgStart }) => (imgStart ? `'col2 col1'` : `'col1 col2' `)};

  @media screen and (max-width: 768px) {
     grid-template-areas: ${({ imgStart }) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};
  }
`;
// img
export const Column1 = styled.div`
 margin-bottom: 15px;
 padding: 0 15px;
 grid-area: col1;
`;
// text
export const Column2 = styled.div`
 margin-top: 10px;
 margin-bottom: 15px 0;
 padding: 0 15px;
 grid-area: col2;
`;

export const TextWrapper = styled.div`
 max-width: 540px;
 padding-top: 0;
 padding-bottom: 60px;
`;

export const TopLine = styled.p`
  color:var(--color-green);
  font-size: 20px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

export const Heading = styled.h1`
   margin-bottom: 24px;
   font-size: 2rem;
   line-height: 1.1;
   font-weight: 600;
   color: ${({ lightText }) => (lightText ? '#f7f8fa' : 'var(--color-dark)')};
   text-shadow: 
                 0px 5px 10px rgba(0,0,0,0.15),
                 0px 7px 2px rgba(0,0,0,0.1),
                 0px 10px 30px rgba(0,0,0,0.1);

   @media screen and (max-width: 480px) {
     font-size: 32px;
   }
`;

export const Subtitle = styled.div`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 15px;
  line-height: 24px;
  color: ${({ darkText }) => (darkText ? 'var(--color-dark)' : '#fff')};
`;

export const Subtitle3 = styled.div`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: ${({ darkText }) => (darkText ? '#fff' : 'var(--color-dark)')};
`;

// For infoSec_2.js
export const SkillsWrap = styled.div`
  color: ${({ darkText }) => (darkText ? 'var(--color-dark)' : '#fff')};
`;


export const BtnWrap = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
`;

export const ImgWrap = styled.div`
   max-width: 555px;
   height: 100%;
`;

export const Img = styled.img`
  width: 100%;
  margin: 20px 0 10px 0;
  padding-right: 0;

  @media screen and (max-width:376px){
    width: 300px;
  }
`;

export const LanguageList = styled.ul`
  display:flex;
  list-style: none;
  margin-bottom:2rem;
  flex-wrap: wrap;
  li{
    font-size:1rem;
    padding:5px;
    border:1px solid var(--color-green);
    border-radius: 8px;
    margin-right: 10px;
    margin-bottom: 10px;
    a{
      text-decoration: none;
      color:var(--color-dark);
    }

    @media (max-width:768px){
    font-size:13px;
  }
  }
 
`;



